import React from 'react';
import {Flex, Box} from '@fluentui/react-northstar'


const Loading = ({handleAnimationEnd}) => {
  return (
      <>
        <style dangerouslySetInnerHTML={{ __html: `
            @keyframes fade {
                0% {
                    opacity: 0;
                }
                50% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }
        ` }}/>
        <Flex style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', flexFlow: 'column', animation: '3s ease-in-out 1s fade', opacity: '0', animationFillMode: 'forwards' }} onAnimationEnd={handleAnimationEnd}>
            <Box style={{ fontSize: '3rem' }}>欢迎来到</Box>
            <Box style={{ fontSize: '2rem' }}>赛博动物园</Box>
        </Flex>
    </>
  );
}

export default Loading;