import React from 'react';
import {Flex, Box} from '@fluentui/react-northstar'
import BLE from '../BLE'
import { toast } from 'react-toastify'

const F02 = () => {
    const root = React.useRef(null)
    const handleAlwaysOn = () => {
        toast.promise(BLE.getInstance().control0102Led('on'), {
            pending: '正在发送切换指令...',
            success: '切换成功',
            error: '切换时发生错误',
            autoClose: 2000
        })
    }
    const handleBlink = () => {
        toast.promise(BLE.getInstance().control0102Led('blink'), {
            pending: '正在发送切换指令...',
            success: '切换成功',
            error: '切换时发生错误',
            autoClose: 2000
        })
    }
    const handleOff = () => {
        toast.promise(BLE.getInstance().control0102Led('off'), {
            pending: '正在发送切换指令...',
            success: '切换成功',
            error: '切换时发生错误',
            autoClose: 2000
        })
    }
    
    React.useEffect(() => {
        root.current.style.opacity = '1'
        if (BLE.getInstance().whoAreYou() === '02V1') {
            // 不需要操作
        }
    }, [])
    return (
        <>
            <style dangerouslySetInnerHTML={{ __html: `
                .btn-wrapper:not(:first-child) {
                    margin-top: 15px;
                }
                
                .btn-wrapper {
                    display: grid;
                    grid-template-columns: 20px auto;
                    grid-gap: 10px;
                    min-width: 250px;
                    height: 35px;
                    margin-left: 10px;
                    transform: skewX(-30deg);
                
                    --bg-color: #fffff0;
                    --font-color: #000000;
                    --bg-color-hovered: #84b1ff;
                    --font-color-hovered: #000000;
                }
                
                .btn-wrapper > * {
                    background-color: var(--bg-color);
                }
                
                .btn-wrapper:hover .btn-tail {
                    background-color: var(--bg-color-hovered);
                }
                
                .btn-body {
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: var(--font-color);
                    height: 3.5rem;
                }
                
                .btn-wrapper:hover .btn-body {
                    color: var(--font-color-hovered);
                }
                
                .btn-body::before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: var(--bg-color-hovered);
                    transition: all 0.3s ease-out;
                }
                
                .btn-wrapper:hover .btn-body::before {
                    width: 100%;
                }
                
                .btn-body p {
                    margin-left: 15px;
                    font-size: 1.5rem;
                    text-transform: uppercase;
                    transform: skewX(30deg);
                }
                
                .btn-tail {
                    transition: all 0s ease-out 0.3s;
                }
                
                .btn-wrapper:hover .btn-tail {
                    transition: none;
                }
                
            ` }}/>
            <Flex ref={root} style={{ justifyContent: 'flex-start', alignItems: 'center', width: '100%', height: '100vh', flexFlow: 'column', opacity: '0', animationFillMode: 'forwards', transition: 'opacity 1.5s' }}>
                <Box style={{ fontSize: '2rem', margin: '0.5rem' }}>欢迎你 『02』</Box>
                <Flex style={{ margin: '0.5rem', flexDirection: 'column' }}>
                    <a href="#" className="btn-wrapper" style={{margin: '2rem'}} onClick={handleAlwaysOn}>
                        <div className="btn-tail"></div>
                        <div className="btn-body">
                            <p>战斗模式<span style={{ fontSize: '0.7rem', marginLeft: '5px' }}>BATTLE</span></p>
                        </div>
                    </a>
                    <a href="#" className="btn-wrapper" style={{margin: '2rem'}} onClick={handleBlink}>
                        <div className="btn-tail"></div>
                        <div className="btn-body">
                            <p>烧鸡模式<span style={{ fontSize: '0.7rem', marginLeft: '5px' }}>ROAST</span></p>
                        </div>
                    </a>
                    <a href="#" className="btn-wrapper" style={{margin: '2rem'}} onClick={handleOff}>
                        <div className="btn-tail"></div>
                        <div className="btn-body">
                            <p>休眠模式<span style={{ fontSize: '0.7rem', marginLeft: '5px' }}>SLEEP</span></p>
                        </div>
                    </a>
                </Flex>
            </Flex>
        </>
    );
}

export default F02;