class BLE {
    static getInstance() {
        if (!BLE.instance) {
            BLE.instance = new BLE();
        }
        return BLE.instance;
    }
    constructor() {
        this.services = {
            'service': '41d22709-71bc-4d75-9c40-a6fb79d8b62a',
            'identify': '1b97bea6-5915-4f87-9502-5f788a1c70b1',
            '02V1': {
                'led': '9e75c377-58c2-4e17-b4a4-4cff8b7599ff'
            },
            '01V1': '02V1'
        }
        if (navigator.bluetooth) {
            this.is_enabled = true
        } else {
            this.is_enabled = false
        }
        this.is_connected = false
        this.events = {
            'onconnect': [],
            'ondisconnect': [],
            'onerror': []
        }
    }
    isEnabled() {
        return this.is_enabled
    }
    isConnected() {
        return this.is_connected
    }
    whoAreYou() {
        return this.im
    }
    async runDiscover() {
        this.deviceService = await this.server.getPrimaryService(this.services.service)
        const identify = await this.deviceService.getCharacteristic(this.services.identify)
        const identifyValue = await identify.readValue()
        const who = identifyValue.getUint16(2, true)
        const versionGen = identifyValue.getUint16(4, true)
        this.is_connected = true
        if (who === 2) {
            if (versionGen === 1) {
                this.im = '02V1'
                await this.init0102()
            } else {
                console.error('未知机体')
                this.events.onerror.forEach((c) => c(null))
                this.disconnect()
                return
            }
        } else if (who === 1) {
            if (versionGen === 1) {
                this.im = '01V1'
                await this.init0102()
            } else {
                console.error('未知机体')
                this.events.onerror.forEach((c) => c(null))
                this.disconnect()
                return
            }
        } else {
            console.error('未知机体')
            this.events.onerror.forEach((c) => c(null))
            this.disconnect()
            return
        }
        this.events.onconnect.forEach((callback) => callback())
    }
    connect() {
        if (!this.isConnected()) {
            navigator.bluetooth.requestDevice({filters:[
                {services: [this.services.service]}
            ]}).then(async (device) => {
                if (device.gatt) {
                    this.device = device
                    if (this.device.gatt.connected) {
                        this.server = this.device.gatt
                        await this.runDiscover()
                    }
                    this.device.gatt.connect().then(async (server) => {
                        this.server = server
                        await this.runDiscover()
                    }).catch((error) => {
                        this.events.onerror.forEach((c) => c(error))
                        this.disconnectAll()
                    })
                } else {
                    this.events.onerror.forEach((c) => c(null))
                    this.disconnectAll()
                }
            }).catch((error) => {
                this.events.onerror.forEach((c) => c(error))
                this.disconnectAll()
            })
        }
    }
    addConnectCallback(callback) {
        this.events.onconnect.push(callback)
    }
    removeConnectCallback(callback) {
        this.events.onconnect = this.events.onconnect.filter((c) => c !== callback)
    }
    addDisconnectCallback(callback) {
        this.events.ondisconnect.push(callback)
    }
    removeDisconnectCallback(callback) {
        this.events.ondisconnect = this.events.ondisconnect.filter((c) => c !== callback)
    }
    addErrorCallback(callback) {
        this.events.onerror.push(callback)
    }
    removeErrorCallback(callback) {
        this.events.onerror = this.events.onerror.filter((c) => c !== callback)
    }
    async init0102() {
        if (this.im === '01V1' || this.im === '02V1') {
            this.control = {
                'led': await this.deviceService.getCharacteristic(this.services['02V1'].led)
            }
        }
    }
    async control0102Led(status) {
        if (this.im !== '02V1' && this.im !== '01V1') return
        let led = 0x00
        if (status === 'off') {
            led = 0x00
        } else if (status === 'blink') {
            led = 0x01
        } else if (status === 'on') {
            led = 0x02
        }
        await this.control.led.writeValue(Uint8Array.from([led]))
    }
    disconnectAll() {
        this.device = undefined
        this.server = undefined
        this.control = undefined
        this.is_connected = false
        this.events.ondisconnect.forEach((callback) => callback())
    }
    disconnect() {
        if (this.server) {
            if (this.device.gatt.connected) {
                this.server.disconnect()
            }
            this.disconnectAll()
        }
    }
}

export default BLE