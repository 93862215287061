import React from 'react';
import {Flex, Box, Button} from '@fluentui/react-northstar'
import { isIOS, isMacOs } from 'react-device-detect';

const NoBLESupport = () => {
  return (
      <>
        <style dangerouslySetInnerHTML={{ __html: `
            @keyframes fade {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        ` }}/>
        <Flex style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', flexFlow: 'column', animation: '1s ease-in-out 0.5s fade', opacity: '0', animationFillMode: 'forwards' }}>
            <Box style={{ fontSize: '1.5rem' }}>您的浏览器</Box>
            <Box style={{ fontSize: '1.5rem' }}>不支持蓝牙连接功能</Box>
            {(isIOS) ? <>
                <Box style={{ fontSize: '1rem' }}>您似乎是 iOS 用户</Box>
                <Box style={{ fontSize: '1rem' }}>推荐下载 <Button content={'Bluefy'} as="a" tinted href="https://apps.apple.com/us/app/bluefy-web-ble-browser/id1492822055" target="_blank"/> 浏览器</Box>
            </> : null}
            {(isMacOs) ? <>
                <Box style={{ fontSize: '1rem' }}>您似乎是 MacOS 用户</Box>
                <Box style={{ fontSize: '1rem' }}>推荐下载 <Button content={'Chrome'} as="a" tinted href="https://www.google.com/chrome/" target="_blank"/> 浏览器</Box>
            </> : null}
        </Flex>
    </>
  );
}

export default NoBLESupport;