import React from 'react';
import {Button, Box} from '@fluentui/react-northstar'
import BLE from './BLE'
import { toast } from 'react-toastify'

const DisconnectBtn = ({disconnected}) => {
    const [shown, setShown] = React.useState(false)
    const [disp, setDisp] = React.useState(false)
    const handleDisconnect = () => {
        if (shown) {
            toast.error('已断开连接')
            setShown(false)
        }
        disconnected()
    }
    const handleConnected = () => {
        setShown(true)
    }
    React.useEffect(() => {
        if (shown) {
            setDisp(true)
        } else {
            setTimeout(() => {
                setDisp(false)
            }, 1600)
        }
    }, [shown])
    React.useEffect(() => {
        BLE.getInstance().addDisconnectCallback(handleDisconnect)
        BLE.getInstance().addConnectCallback(handleConnected)
        return () => {
            BLE.getInstance().removeDisconnectCallback(handleDisconnect)
            BLE.getInstance().removeDisconnectCallback(handleConnected)
        }
    }, []) // eslint-disable-line
  return (
      <>
        <Box style={{ position: 'fixed', top: '-3rem', right: '-3rem', opacity: (shown ? 1 : 0), animationFillMode: 'forwards', transition: 'opacity 1.5s', visibility: (disp ? '': 'hidden') }}>
            <Button disabled={!shown} circular content={<span style={{ position: 'absolute', left: '1.5rem', bottom: '1.5rem' }}>X</span>} flat loader="Disconnect" style={{ color: 'black', borderRadius: '71.3571rem', width: '6rem', height: '6rem', backgroundColor: 'white' }} onClick={() => {BLE.getInstance().disconnect()}}/>
        </Box>
      </>
  );
}

export default DisconnectBtn;