import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider, teamsDarkV2Theme } from '@fluentui/react-northstar'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider theme={teamsDarkV2Theme}>
      <App />
      <ToastContainer 
        theme="dark"
        position="bottom-center"
        autoClose={3000}
        limit={3}
      />
    </Provider>
  </React.StrictMode>
);