import React from 'react';
import {Flex} from '@fluentui/react-northstar'
import Loading from './components/Loading'
import Connect from './components/Connect'
import NoBLESupport from './components/NoBLESupport';
import BLE from './components/BLE'
import F02 from './components/control/F02'
import DisconnectBtn from './components/DisconnectBtn';

const App = () => {
  const showSpecficControl = {
    im02: () => {
      setFrame(<F02 />)
    }
  }
  const loadMain = () => {
    if (BLE.getInstance().isEnabled()) {
      setFrame(<Connect control={showSpecficControl} />)
    } else {
      setFrame(<NoBLESupport />)
    }
  }
  React.useEffect(() => {
    window.control = {
      changeTo02: () => {
        setFrame(<F02 />)
      }
    }
    return () => {
      window.control = undefined
    }
  }, [])
  const [frame, setFrame] = React.useState(<Loading handleAnimationEnd={loadMain}/>)
  return (
    <Flex>
        <DisconnectBtn disconnected={loadMain}/>
        {frame}
    </Flex>
  );
}

export default App;
