import React from 'react';
import {Flex, Box} from '@fluentui/react-northstar'
import BLE from './BLE'
import { toast } from 'react-toastify'

const Connect = ({control}) => {
    const connectToastRef = React.useRef(null)
    const handleConnect = () => {
        connectToastRef.current = toast.info('正在连接...', {
            autoClose: false
        })
        BLE.getInstance().connect()
    }
    const root = React.useRef(null)
    const handleConnected = () => {
        if (connectToastRef.current)
            toast.update(connectToastRef.current, {
                render: '已完成连接，正在确认机体...',
                type: toast.TYPE.INFO, autoClose: 2000
            })
        const who = BLE.getInstance().whoAreYou()
        if (who === '02V1') {
            toast.success('欢迎 『02』',{
                autoClose: 2000
            })
        } else {
            toast.error('未知机体')
        }
        root.current.style.opacity = '0'
        setTimeout(() => {
            if (who === '02V1') {
                control.im02()
            } else {
                console.error('未知')
            }
        }, 1700)
    }
    const handleErrorConnect = (error) => {
        if (error.code !== 8) {
            console.error(error)
            if (connectToastRef.current)
                toast.update(connectToastRef.current, {
                    render: '未能连接到机体',
                    type: toast.TYPE.ERROR, autoClose: 5000
                })
        } else {
            if (connectToastRef.current)
                toast.update(connectToastRef.current, {
                    render: '连接已取消',
                    type: toast.TYPE.WARNING, autoClose: 2000
                })
        }
    }
    React.useEffect(() => {
        setTimeout(() => {
            root.current.style.opacity = '1'
        }, 500)
        BLE.getInstance().addConnectCallback(handleConnected)
        BLE.getInstance().addErrorCallback(handleErrorConnect)
        return () => {
            BLE.getInstance().removeConnectCallback(handleConnected)
            BLE.getInstance().removeErrorCallback(handleErrorConnect)
        }
    }, []) // eslint-disable-line
    return (
        <>
            <style dangerouslySetInnerHTML={{ __html: `
                .btn-wrapper:not(:first-child) {
                    margin-top: 15px;
                }
                
                .btn-wrapper {
                    display: grid;
                    grid-template-columns: 20px auto;
                    grid-gap: 10px;
                    min-width: 250px;
                    height: 35px;
                    margin-left: 10px;
                    transform: skewX(-30deg);
                
                    --bg-color: #fffff0;
                    --font-color: #000000;
                    --bg-color-hovered: #84b1ff;
                    --font-color-hovered: #000000;
                }
                
                .btn-wrapper > * {
                    background-color: var(--bg-color);
                }
                
                .btn-wrapper:hover .btn-tail {
                    background-color: var(--bg-color-hovered);
                }
                
                .btn-body {
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: var(--font-color);
                    height: 3.5rem;
                }
                
                .btn-wrapper:hover .btn-body {
                    color: var(--font-color-hovered);
                }
                
                .btn-body::before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: var(--bg-color-hovered);
                    transition: all 0.3s ease-out;
                }
                
                .btn-wrapper:hover .btn-body::before {
                    width: 100%;
                }
                
                .btn-body p {
                    margin-left: 15px;
                    font-size: 1.5rem;
                    text-transform: uppercase;
                    transform: skewX(30deg);
                }
                
                .btn-tail {
                    transition: all 0s ease-out 0.3s;
                }
                
                .btn-wrapper:hover .btn-tail {
                    transition: none;
                }
                
            ` }}/>
            <Flex ref={root} style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', flexFlow: 'column', opacity: '0', animationFillMode: 'forwards', transition: 'opacity 1.5s ease 0.5s' }}>
                <Box style={{ fontSize: '2rem', margin: '0.5rem' }}>赛博动物园</Box>
                <Box style={{ margin: '0.5rem' }}>
                    <a href="#" className="btn-wrapper" onClick={handleConnect}>
                        <div className="btn-tail"></div>
                        <div className="btn-body">
                            <p>开始连接<span style={{ fontSize: '0.7rem', marginLeft: '5px' }}>CONNECT</span></p>
                        </div>
                    </a>
                </Box>
            </Flex>
        </>
    );
}

export default Connect;